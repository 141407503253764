/* eslint-disable */
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
// import store from "../../store/index";
import { useStore } from "vuex";
import { watch, ref, onMounted, onUnmounted, onBeforeMount } from 'vue'
import { encryptUser } from "../../common/encryption"
import { useRouter } from "vue-router";
import axios from "axios";


var webAuth = new auth0.WebAuth({
	domain: process.env.VUE_APP_Domain,
	clientID: process.env.VUE_APP_Client_ID,
	scope: "openid profile email offline_access",
	responseType: "token id_token",
	useRefreshTokens: true,
});

export default {
	name: "login",
	setup() {
		const store = useStore();
		const router = useRouter();
		const username = ref("");
		const password = ref("");
		const externaluser = ref(false);
		const errr = ref("");
		const errorBlock = ref(false);
		const authResult = ref("");
		let urlstring = ref("");
		let usertype = ref("");
		const inputType = ref("password")
		let slitstring = ref([]);
		let baseurl = ref(process.env.VUE_APP_Service_URL);
		let eachdata = ref({});
		const visibleOn = ref(false);

		onBeforeMount(async () => {
			await store.dispatch("setAuthorize", false);
			await store.dispatch("setToken", null);
			localStorage.clear();
		})

		onMounted(async () => {

			store.dispatch("setdocumenttodepartment", false);
			await checkforfileorfolder();
			let pagewrapper = document.querySelector("#page-wrapper");
			pagewrapper.style.width = "100%";
			await store.dispatch("setNavbar", false);
			await store.dispatch("setAuthorize", false)
		})
		onUnmounted(async () => {
			let pagewrapper = document.querySelector("#page-wrapper");
			pagewrapper.style.width = "";
			await store.dispatch("setNavbar", true);
			await store.dispatch("setAuthorize", true)
			store.dispatch("setDocLog", false)
		})

		async function checkforfileorfolder() {
			urlstring.value = location.href.substring(location.href.indexOf('?') + 1);

			slitstring.value = urlstring.value.split("&");
			slitstring.value.forEach((eachval) => {
				let valarray = eachval.split("=");
				eachdata.value[valarray[0]] = valarray[1]
			})
			username.value = eachdata.value["EmailId"]
		}
		async function Enablemenu() {
			await store.dispatch("setNavbar", true);
		}
		async function login() {
			errorBlock.value = false
			const encryptedPassword = await encryptUser(password.value.trim());
			await axios
				.post(
					`${baseurl.value}account-core/user/userauthorize`, {
					"userEmail": username.value,
					"password": encryptedPassword
				}
				)
				.then(async (res) => {
					authResult.value = res.data
					console.log("Entered login 5555",urlstring.value)
					if (urlstring.value != "") {
						console.log("Navbar  value is", store.getters.getSideNavbarstatus)
						store.dispatch("setToken", authResult.value.idToken)
						var decoded = jwt_decode(authResult.value.idToken);
						store.dispatch("setOrganizationId", decoded.organization_id)
						await setuserrole(authResult.value);
						if (eachdata.value.hasOwnProperty('ExternalUserFolder') && eachdata.value["ExternalUserFolder"]) {
							console.log("Entered login 3333")
							store.dispatch("setIsexternaluser", "True")
							router.push(`/sharedfolder`);
						}

						else {
							store.dispatch("setIsexternaluser", "False")
							await store.dispatch("setSideNavbar", true);

							await setuserrole(authResult.value);
							if (eachdata.value["Type"] == "file") {
								store.dispatch("setDocViewSource", "Share")
								router.push(`/documentview/${eachdata.value["DocumentId"]}` + "/" + 0);
							}
							else if (eachdata.value["Type"] == "ChangeControl") {
								store.dispatch("setDocViewSource", "changeControl")
								router.push(`/controlRecords/` + eachdata.value["FolderId"] + "/" + eachdata.value["FormId"]);
							}
							else if (eachdata.value["Type"] == "Folder" && eachdata.value["Page"] == "Department") {
								console.log("Next check")
								store.dispatch("setshaioreddept", eachdata.value["Department"]);
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								router.push(`/department`);
							}
							else if (eachdata.value["Type"] == "Folder" && eachdata.value["Storagetype"] == "General") {
								// console.log("Next check")
								// store.dispatch("setshaioreddept", eachdata.value["ProId"]);
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								store.dispatch("setopenfolder", eachdata.value["FolderId"]);
								store.dispatch("selectFolder", eachdata.value["FolderId"]);
								router.push(`/generalFiles`);
								// router.push(`/shairedfolder`);


							}
							else if (eachdata.value["Type"] == "Folder" && eachdata.value["Storagetype"] == "Department") {
								console.log("Next check")
								store.dispatch("setshaioreddept", eachdata.value["ProId"]);
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								store.dispatch("setopenfolder", eachdata.value["FolderId"]);
								store.dispatch("selectFolder", eachdata.value["FolderId"]);
								router.push(`/department`);
							}
							else if (eachdata.value["Type"] == "Folder" && eachdata.value["Storagetype"] == "Project") {


								let folderidforsave = eachdata.value["FolderId"];
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								store.dispatch("setopenfolder", eachdata.value["FolderId"]);
								store.dispatch("selectFolder", eachdata.value["FolderId"]);
								console.log("Entered login", eachdata.value["FolderId"]);
								router.push(`/project_summary/${eachdata.value["ProId"]}/${eachdata.value["ProjectType"]}/${eachdata.value["ProjectName"]}`);
							}
							else if (eachdata.value["Type"] == "Folder" && (eachdata.value["Storagetype"] == "Project" || eachdata.value["Storagetype"] == "Project%20Documents")) {
								let folderidforsave = eachdata.value["FolderId"];
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								store.dispatch("setopenfolder", eachdata.value["FolderId"]);
								store.dispatch("selectFolder", eachdata.value["FolderId"]);
								console.log("Entered login", eachdata.value["FolderId"]);
								router.push(`/documentList/${eachdata.value["ProId"]}/${eachdata.value["ProjectName"]}`);
							}
							else if (eachdata.value["Type"] == "File" && (eachdata.value["Page"] == "Project" || eachdata.value["Storagetype"] == "Project%20Documents")) {
								//store.dispatch("setshaioreddept", eachdata.value["Project"]);
								store.dispatch("setshaioredfolder", eachdata.value["FolderId"]);
								router.push(`/documentview/${eachdata.value["DocumentId"]}` + "/" + eachdata.value["Version"]);
							}
							else if (eachdata.value["DocumentId"] && eachdata.value["EmailId"] && eachdata.value["Version"]) {
								console.log("Entered login 111111111")
								router.push(`/documentview/${eachdata.value["DocumentId"]}` + "/" + eachdata.value["Version"]);
							}

							else if (eachdata.value["TargetLocation"] == "ViewDocument") {
								console.log("Entered login 2222")
								if (eachdata.value["R"] == "1") {
									store.dispatch("setRetest", "1")
								}
								store.dispatch("setDocViewSource", "Mail")
								router.push(`/documentview/${eachdata.value["DocumentId"]}` + "/" + 1);

							}
							else if (eachdata.value["TargetLocation"] == "OnlineExam") {
								store.dispatch("setDocViewSource", "Mail")
								store.dispatch("setprvspath", "/doclogin/"),
									console.log("This is online exam");
								router.push(`/myQuestion/${eachdata.value["ConfigId"]}` + "/" + `${eachdata.value["usersExamId"]}`);
							}
							else if (eachdata.value["TargetLocation"] == "myQuestion") {

								if (eachdata.value["R"] == "1") {
									store.dispatch("setRetest", "1")
								}
								store.dispatch("setDocViewSource", "Mail")
								router.push(`/myQuestion/${eachdata.value["ConfigId"]}` + "/" + `${eachdata.value["usersExamId"]}`);

							}
							else if (eachdata.value["TargetLocation"] == "sop_acknoledgment") {
								store.dispatch("setUserEmail", eachdata.value["EmailId"])
								if (eachdata.value["role"] == ["TraineeSignature"]) {

									router.push(`/sop_acknoledgment/${eachdata.value["ackid"]}/TraineeSignature`)
								}
								else if (eachdata.value["role"] == ["TrainerSignature"]) {
									router.push(`/sop_acknoledgment/${eachdata.value["ackid"]}/TrainerSignature`)
								}
							}
						}
					}

				})
				.catch((err) => {
					if (err) {
						if (err.response && err.response.data) {
							if (err.response.data && err.response.data == "An error occurred while authenticating.") {
								errr.value = "The password field is required";
							}
						}
						if (err.response && err.response.data == "Invalid email or password.") {
							errorBlock.value = true
						}
					}
				})
		}
		async function setuserrole(authresult) {
			console.log(authresult);
			const idtoken = authresult.idToken;
			const userid = (jwt_decode(idtoken).sub).trim();
			await axios
				//.get(`https://localhost:5001/user/getuserwithauthid?guid=`+userid, {
				.get(`${baseurl.value}account-core/user/getuserwithauthid?guid=` + userid, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					console.log("rolls are", res.data);
					console.log(res.data.designation);
					console.log("userid is", res.data.id);
					store.dispatch("setroles", res.data.designation);
					await externalorinternal(res.data.id, authresult);
					await getrollprevilage(res.data.designation, authresult);
				})
				.catch((err) => {
					console.log(err);
				});
		}
		async function externalorinternal(id, authresult) {
			const idtoken = authresult.idToken;
			await axios
				.get(`${baseurl.value}account-core/user/get?id=` + id, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					console.log("userid is", res.data);
					usertype.value = res.data.userType;
				})
				.catch((err) => {
					console.log(err);
				});
		}
		async function getrollprevilage(designation, authresult) {
			const idtoken = authresult.idToken;
			// let array = (store.getters.getRolesData).split(',');

			console.log("rolldata is");
			// console.log(eachrole);
			await axios
				.get(`${baseurl.value}account-core/roles/getscreendesignationprivilegebyid?guid=${designation}`, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					if (res.data != "No ScreenRolePrivilege found" && res.data != "No Privilege found") {
						console.log("The privilages", res.data.screens);
						// store.dispatch("setrolesprivileges", res.data.screens);
						await setrolesprivileges(res.data.screens, authresult);
					}
					else {
						if (eachdata.value.hasOwnProperty('ExternalUserFolder') && eachdata.value["ExternalUserFolder"]) {

							await setrolesprivileges(["shairedfolder"], authresult);

						}
					}
				})
				.catch((err) => {
					console.log(err);
				});


		}
		async function setrolesprivileges(data, authresult) {

			console.log("rolll privileges are .............................");
			console.log(data);
			let privilegevalues = [];
			await (data).forEach((screen) => {
				for (let j = 0; j < screen.controls.length; j++) {
					if (!privilegevalues.includes(screen.name + " " + screen.controls[j])) {
						privilegevalues.push(screen.name + " " + screen.controls[j]);
					}
				}
			});
			console.log("rolll privileges array issssssssssss .............................");
			console.log(privilegevalues);
			let userprivilegestring = privilegevalues.join();
			console.log("rolll privileges string issssssssssss .............................");
			console.log(userprivilegestring);
			await store.dispatch("setrolesprivileges", `test test,${userprivilegestring}`);
			//await gotopages(authresult);
		}
		async function passwordVisible() {
			visibleOn.value = !visibleOn.value
			if (visibleOn.value == true) {
				inputType.value = "text"
			}
			else inputType.value = "password"
		}
		async function testcompleteduser() {
			await axios
				.get(
					`${this.baseUrl}document/documentquestion/getexamcompleteduser?documentid=${this.documentid}&configId=${this.configid}`,
					{
						headers: {
							Authorization: "Bearer " + this.idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.xam = res.data;
					console.log("valueeeeeeeeee is", this.xam)
				})
				.catch((err) => {
					console.log(err);
				});
		}

		return {
			username,
			password,
			externaluser,
			errorBlock,
			errr,
			xam: false,
			Enablemenu,
			login,
			passwordVisible,
			visibleOn,
			inputType,
			authResult,
			testcompleteduser,
		};

	},


}