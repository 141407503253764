/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  name: "create_organization",
  props: { orgId: String },
  // components: {
  //     //VPagination
  // },
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/organization/`,
      SaveButtonText: "",
      IsUpdate: false,
      CreateUpdateCommand: {
        orgName: "",
        orgCode: "",
        orgType: "",
        orgRegNum: "",
        mainContactDesg: "",
        primaryContactNo: "",
        secondaryContactNo: "",
        primaryEmail: "",
        secondaryEmail: "",
        active: true,
        addressFloor: "",
        addressStreet: "",
        city: "",
        states: "",
        country: "",
        postalCode: "",
        orgWebURL: "",
        logo: "",
        contactName: "",
      },
      UserDto: {
        orgName: "",
        orgCode: "",
        orgType: "",
        orgRegNum: "",
        mainContactDesg: "",
        primaryContactNo: "",
        secondaryContactNo: "",
        primaryEmail: "",
        secondaryEmail: "",
        active: true,
        addressFloor: "",
        addressStreet: "",
        city: "",
        states: "",
        country: "",
        postalCode: "",
        orgWebURL: "",
        logo: "",
        contactName: "",
      },
      documentId: "",
      azuredata: {
        Document: "",
        DocumentType: "Organization"
      },
      documentById: "",
      validations: [false, false, false,true],
      errors: [],
    };
  },
  async mounted() {
    this.SaveButtonText = "Save";
    this.IsUpdate = false;
    if (this.orgId != 0) {
      await this.GetAndSetData(this.orgId);
      this.SaveButtonText = "Update";
      this.IsUpdate = true;
    }
    this.errors = []
  },
  methods: {
    Clear() {
      this.UserDto = {
        orgName: "",
        orgCode: "",
        orgType: "",
        orgRegNum: "",
        mainContactDesg: "",
        primaryContactNo: "",
        secondaryContactNo: "",
        primaryEmail: "",
        secondaryEmail: "",
        active: "",
        addressFloor: "",
        addressStreet: "",
        city: "",
        states: "",
        country: "",
        postalCode: "",
        orgWebURL: "",
        logo: "",
        contactName: ""
      }
    },
    SetCommandAsync() {
      this.CreateUpdateCommand.orgId = this.UserDto.orgId;
      this.CreateUpdateCommand.orgName = this.UserDto.orgName;
      this.CreateUpdateCommand.orgCode = this.UserDto.orgCode;
      this.CreateUpdateCommand.orgType = this.UserDto.orgType;
      this.CreateUpdateCommand.orgRegNum = this.UserDto.orgRegNum;
      this.CreateUpdateCommand.mainContactDesg = this.UserDto.mainContactDesg;
      this.CreateUpdateCommand.primaryContactNo = this.UserDto.primaryContactNo;
      this.CreateUpdateCommand.secondaryContactNo = this.UserDto.secondaryContactNo;
      this.CreateUpdateCommand.primaryEmail = this.UserDto.primaryEmail;
      this.CreateUpdateCommand.secondaryEmail = this.UserDto.secondaryEmail;
      this.CreateUpdateCommand.active = this.UserDto.active;
      this.CreateUpdateCommand.addressFloor = this.UserDto.addressFloor;
      this.CreateUpdateCommand.addressStreet = this.UserDto.addressStreet;
      this.CreateUpdateCommand.city = this.UserDto.city;
      this.CreateUpdateCommand.states = this.UserDto.states;
      this.CreateUpdateCommand.country = this.UserDto.country;
      this.CreateUpdateCommand.postalCode = this.UserDto.postalCode;
      this.CreateUpdateCommand.orgWebURL = this.UserDto.orgWebURL;
      this.CreateUpdateCommand.logo=this.UserDto.logo;
      this.CreateUpdateCommand.contactName = this.UserDto.contactName;
    },
    async onclosed() {
      this.$router.push(`/orglisting`);
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    async UpdateAsync() {
      console.log("dtooo",this.UserDto.logo)
     // if(this.UserDto.logo==0){
      await this.submitFile();
      this.CreateUpdateCommand.logo = this.documentId;
      if (this.errors[0]) {
        if (this.errors[1]) {
          if(this.errors[2]){
          await axios
            .put(
              this.baseurl + "update",
              this.CreateUpdateCommand
            )
            .then((res) => {
              alert("Data Updated Successfully");
              this.$router.push(`/orglisting`);

            })
            .catch((err) => {
              console.log(err);
              alert("Organization name alreadt exist")
            });
          }
        }
      }
    },
    // async viewDocument(docId) {
    //   //await this.getDocumentById(docId);
    //   await this.getdmsdocument(docId);
    //   const versionId =
    //     this.documentById.documentVersions[
    //       this.Document.documentVersions.length - 1
    //     ].documentVersionId;

    //   const filename =
    //     this.documentById.documentVersions[
    //       this.Document.documentVersions.length - 1
    //     ].fileName;
    //   this.$router.push(
    //     `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
    //   );
    // },
    // async getdmsdocument(docId) {
    //   console.log("document download", this.Document);
    //   await axios
    //     .get(
    //       `https://eidsa.dev.datamatica.uk/v1/dms/document/getallfiles/${docId}`,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + this.idToken,

    //           "Content-Type": "application/json",
    //           responseType: "blob",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.documentById = res.data;
    //       console.log("Docdownld............", this.documentById);
    //     })
    //     .catch((err) => {
    //       alert(this.$t("navigations.document is not available"));
    //       console.log(err);
    //     });
    // },
    async validateIsNull() {
      if (this.CreateUpdateCommand.orgName == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else if (this.CreateUpdateCommand.orgName.trim("") == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else {
        this.validations[0] = true;
        this.errors = this.validations;
      }
    },
    async ValidatefirstEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        this.validations[1] = true;
        this.errors[1] = true;
      }
      else {
        this.validations[1] = false;
        this.errors[1] = false;
      }
    },
    async ValidateSecEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        console.log("first")
        this.validations[3] = true;
        this.errors[3] = true;
      }
      else if(mail==""){
        console.log("sec")
        this.validations[3] = true;
        this.errors[3] = true;
      }
      else {
        console.log("third")
        this.validations[3] = false;
        this.errors[3] = false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    async ValidateOrgUrl(mail) {
      const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (regex.test(mail)) {
        this.validations[2] = true;
        this.errors[2] = true;
      }
      else {
        this.validations[2] = false;
        this.errors[2] = false;
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.azuredata.Document);
      formData.append("DocumentTypeName", this.azuredata.DocumentType);
      await axios
        .post(`https://eidsa.dev.datamatica.uk/v1/dms/document/create`, formData, {
          headers: {
            Authorization: "Bearer " + this.idToken,

            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          console.log("retreieved data", response);
          this.documentId = response.data.data[0];
          this.documentVersionId = [];
          this.documentVersionId = response.data.data[1];
          // this.Createdocument();
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async GetAndSetData(orgId) {
      await axios
        .get(this.baseurl + "getbyid?id=" + orgId)
        .then((res) => {
          this.UserDto = res.data;
          console.log(this.UserDto);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async SaveButtonClicked() {
      this.UserDto.orgName = this.UserDto.orgName.trim();
      this.UserDto.orgCode = this.UserDto.orgCode.trim();
      this.UserDto.orgRegNum = this.UserDto.orgRegNum.trim();
      this.UserDto.mainContactDesg = this.UserDto.mainContactDesg.trim();
      this.UserDto.primaryEmail = this.UserDto.primaryEmail.trim();
      this.UserDto.secondaryEmail = this.UserDto.secondaryEmail.trim();
      this.UserDto.addressFloor = this.UserDto.addressFloor.trim();
      this.UserDto.addressStreet = this.UserDto.addressStreet.trim();
      this.UserDto.city = this.UserDto.city.trim();
      this.UserDto.states = this.UserDto.states.trim();
      this.UserDto.country = this.UserDto.country.trim();
      this.UserDto.orgWebURL = this.UserDto.orgWebURL.trim();
      this.UserDto.contactName = this.UserDto.contactName.trim();
      this.SetCommandAsync();
      await this.validateIsNull();
      await this.ValidatefirstEmail(this.CreateUpdateCommand.primaryEmail);
      await this.ValidateSecEmail(this.CreateUpdateCommand.secondaryEmail);
      await this.ValidateOrgUrl(this.CreateUpdateCommand.orgWebURL);
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else {
        if (this.orgId != 0) {
          await this.UpdateAsync();
        }
        else {
          await this.CreateAsync();
        }

      }
    },
    async CreateAsync() {
      await this.submitFile();
      this.CreateUpdateCommand.logo = this.documentId;
      if (this.errors[0]) {
        if (this.errors[1]) {
          if (this.errors[2]) {
            await axios
              .post(this.baseurl + "create", this.CreateUpdateCommand, {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                console.log(res);
                alert("Organization created successfully");
                this.showOrg = false;
                this.OrgData = !this.OrgData;
                document.body.style.overflowY = "auto"
                this.$router.push(`/orglisting`);
              })
              .catch((err) => {
                console.log(err);
                alert("Organization name already exist");
              });
          }
        }
      }
      this.errors = [];

    },
  },
};